.cart-container {
  padding: 2vw;
}
.cart-container .empty-cart {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
}
.cart-container .cart-divisions {
  display: flex;
}
.cart-container .cart-divisions .division1 {
  flex: 2;
  max-height: 70vh;
  overflow-y: auto;
}
.cart-container .cart-divisions .division1::-webkit-scrollbar {
  display: none;
}
.cart-container .cart-divisions .division1 .cart-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: whitesmoke;
  padding: 1vw 2vw;
}
.cart-container .cart-divisions .division1 .cart-products-container {
  display: flex;
  flex-direction: column;
  gap: 1vw;
}
.cart-container .cart-divisions .division1 .cart-products-container .cart-product {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2vw;
}
.cart-container .cart-divisions .division1 .cart-products-container .cart-product .product-details {
  display: flex;
  flex-direction: column;
  gap: 0.5vw;
  max-width: 10vw;
  flex-wrap: wrap;
}
.cart-container .cart-divisions .division1 .cart-products-container .cart-product .product-details .image-container {
  width: 10vw;
  height: auto;
  border: 0.1vw solid whitesmoke;
  cursor: pointer;
}
.cart-container .cart-divisions .division1 .cart-products-container .cart-product .product-details .image-container img {
  width: inherit;
  height: inherit;
  -o-object-fit: cover;
     object-fit: cover;
}
.cart-container .cart-divisions .division1 .cart-products-container .cart-product .product-details .price-in-cart {
  font-style: oblique;
}
.cart-container .cart-divisions .division1 .cart-products-container .cart-product .product-details .remove {
  cursor: pointer;
  color: red;
}
.cart-container .cart-divisions .division1 .cart-products-container .cart-product .quantity-handler {
  display: flex;
  align-items: center;
  gap: 1vw;
  padding: 2vw;
  border: 0.2vw solid whitesmoke;
}
.cart-container .cart-divisions .division1 .cart-products-container .cart-product .quantity-handler button {
  font-size: 1.5vw;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.cart-container .cart-divisions hr {
  border: 1px solid lightgray;
}
.cart-container .cart-divisions .division2 {
  flex: 1;
}
.cart-container .cart-divisions .division2 .cart-total-section {
  padding: 5vw;
  display: flex;
  flex-direction: column;
  gap: 1vw;
}
.cart-container .cart-divisions .division2 .cart-total-section .subtotal,
.cart-container .cart-divisions .division2 .cart-total-section .tax,
.cart-container .cart-divisions .division2 .cart-total-section .total {
  display: flex;
  justify-content: space-between;
}
.cart-container .cart-divisions .division2 .checkout-section {
  padding: 2vw;
  display: flex;
  justify-content: flex-end;
}
.cart-container .cart-divisions .division2 .checkout-section button {
  padding: 1vh 3vw;
  font-size: 2vw;
  border-radius: 5px;
  border: 0.3vw solid whitesmoke;
  background-color: white;
  cursor: pointer;
  font-weight: bold;
}
.cart-container .cart-divisions .division2 .ship-info {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1vw;
}

@media (max-width: 768px) {
  .cart-container .cart-divisions {
    margin: 4vw;
    padding: 10vw 0 5vw 0;
    border-radius: 3vw;
    background-color: whitesmoke;
    flex-direction: column;
  }
  .cart-container .cart-divisions hr {
    display: none;
  }
  .cart-container .cart-divisions .division1 {
    display: flex;
    flex-direction: column;
    gap: 5vw;
  }
  .cart-container .cart-divisions .division1 .cart-products-container {
    align-items: stretch;
    padding: 0 8vw;
  }
  .cart-container .cart-divisions .division1 .cart-products-container .cart-product {
    padding: 0;
  }
  .cart-container .cart-divisions .division1 .cart-products-container .cart-product .product-details {
    padding: 0;
    max-width: -moz-min-content;
    max-width: min-content;
  }
  .cart-container .cart-divisions .division1 .cart-products-container .cart-product .product-details .image-container {
    width: 20vw;
  }
  .cart-container .cart-divisions .division1 .cart-products-container .cart-product .quantity-handler {
    border-color: black;
  }
  .cart-container .cart-divisions .division1 .cart-products-container .cart-product .quantity-handler button {
    font-size: 3vw;
  }
  .cart-container .cart-divisions .division1 .cart-head {
    background-color: black;
    color: white;
    padding: 2vw 8vw;
  }
  .cart-container .cart-divisions .division2 .cart-total-section {
    padding: 5vw 9vw;
  }
  .cart-container .cart-divisions .division2 .checkout-section {
    justify-content: center;
  }
  .cart-container .cart-divisions .division2 .checkout-section button {
    background-color: black;
    color: white;
    font-size: 3vw;
    border-radius: 2vw;
  }
  .cart-container .cart-divisions .division2 .ship-info {
    font-size: 2vw;
  }
}/*# sourceMappingURL=Cart.css.map */