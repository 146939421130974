
.navbar_main {
  height: auto;
  background-color: whitesmoke;

  .main_menu {
    z-index: 999;
    // padding-top: 2vw;
    padding-bottom: 1vw;

    .menu-icons {
      padding: 0 3vw;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .left-menu {
        display: flex;
        align-items: center;
        gap: 3vw;
        z-index: 9;
        padding-top: 2vw;
        .mobile-menu-icon {
          display: none;
          // padding-top: 1.5vw;
        }
        .search {
          position: relative;
          display: flex;
          align-items: center;
          gap: 1vw;
          // padding-top: 1vh;
          padding-right: 1vh;
          .search_icon {
            img {
              width: 1.5vw;
              filter: invert(100%);
            }
          }
          .search_icon:hover + .search-input,
          .search-input:focus {
            opacity: 1;
            pointer-events: auto;
            transition: opacity 1s;
          }

          .search-input {
            opacity: 0;
            transition: 1s;
            border: none;
            width: 15vw;
            border-radius: 5px;
            padding: 0.5vw 1vw;
            background-color: transparent;
            outline: 2px solid #c7b199;
            color: #c7b199;
            &::placeholder {
              font-size: 1vw;
            }
            :focus + .search-input {
              display: block;
            }
          }
        }
        .search-results {
          position: absolute;
          top: 120%;
          left: 0;
          right: 0;
          background-color: white;
          border-radius: 0 0 5px 5px;
          box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
          z-index: 5;
          display: flex;
          flex-direction: column;
          gap: 1vh;
          max-height: 40vh;
          overflow-y: auto;
          padding: 1vw 0;
          .search-result {
            display: flex;
            align-items: center;
            gap: 2vw;
            padding: 1vh 2vw;
            cursor: pointer;
            background-color: whitesmoke;

            img {
              width: 6vw;
            }
            span {
              font-size: 1.5vw;
            }
          }
        }
        .no-search-results {
          position: absolute;
          top: 120%;
          left: 0;
          right: 0;
          background-color: white;
          border-radius: 0 0 5px 5px;
          box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
          z-index: 5;
          padding: 1vw;
          span {
            font-size: 2vw;
          }
        }
      }

      .center-menu {
        position: sticky;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .menu-logo-container {
          padding: 1vw 3vw;
          img {
            width: 6vw;
            cursor: pointer;
          }
        }
        .menu-list {
          padding: 0;
          margin: 0;
          display: flex;
          gap: 3vw;
          align-items: center;
          // position: relative;

          li {
            list-style: none;
            font-size: 0.75vw;
            color: #c7b199;
            a {
              text-decoration: none;
              color: #c7b199;
            }
            .arrow {
              font-size: 1vw;
              vertical-align: bottom;
            }
          }
        }
      }

      .right-menu {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 2vw;
        gap: 1vw;
        .cart_icon {
          cursor: pointer;
          z-index: 5;
          position: relative;
          img {
            width: 1.5vw;
            filter: invert(100%);
          }
          .cartCount {
            position: absolute;
            top: -5px;
            right: -5px;
            background-color: red;
            color: white;
            border-radius: 50%;
            padding: 2px 5px;
            font-size: 12px;
          }
        }
        .user_box {
          display: flex;
          flex-direction: column;
          z-index: 5;
          margin: 0;
          padding: 0;
          font-size: 2.25vw;
          position: relative;
          .user_icon {
            img {
              width: 1.5vw;
              filter: invert(100%);
            }
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 0.5vw;

            .user-name {
              border: 1px solid whitesmoke;
              border-radius: 5px;
              color: black;
              background-color: white;
              padding: 0.25vw;
            }
          }

          .account-options {
            background-color: white;
            display: flex;
            flex-direction: column;
            padding: 0.25vw;
            position: absolute;
            top: 110%;
            left: 50%;

            transform: translate(-50%, 0);
            z-index: 5;
            border-radius: 0.25vw;
            gap: 0.5vw;
            button {
              font-size: 1vw;
              width: 7vw;
              outline: none;
              border: none;
              background-color: white;
              cursor: pointer;
              font-weight: bold;
            }
          }
        }
      }
    }
    .shoplist-category {
      background-color: white;
      box-shadow: rgba(5, 5, 5, 0.918) 0px 5px 15px;
      padding: 0.5vw 1vw;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 1vw 1vw;

      ul {
        display: flex;
        justify-content: center;
        align-items: center;
        list-style: none;
        flex-wrap: wrap;
        gap: 1vw;
        padding: 0;
        li {
          flex-basis: 30%;
          text-align: center;

          a {
            text-decoration: none;
            color: black;
            .shoplist-category-item {
              background-color: white;
            }
          }
        }
      }
    }
  }

  .sideBar {
    display: none;
  }

  .account-mobile {
    display: none;
  }

  .username-mobile {
    display: none;
  }
}

@media (max-width: 768px) {
  .navbar_main {
    .sideBar {
      display: block;
      position: fixed;
      top: 0;
      left: -100%; /* Start with the sidebar off-screen to the left */
      height: 100%;
      width: 100%; /* Adjust the width as needed */
      background-color: whitesmoke;
      z-index: 10;
      transition: left 0.3s ease-in-out; /* Use left for smooth transition */

      &.open {
        left: 0; /* Bring the sidebar into view */
      }

      .sidebarContent {
        display: flex;
        flex-direction: column;
        padding-top: 5vw;
        height: inherit;
        .sidebar-logo {
          text-align: center;
          img {
            width: 20vw;
          }
        }
        .shoplist-category {
          height: auto;
          padding: 5vw 0;
          background-color: #c7b199;
          ul {
            display: flex;
            height: inherit;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: center;
            padding: 0;
            align-items: center;
            gap: 7vh;
            list-style: none;
            font-size: 4vw;

            li {
              a {
                text-decoration: none;
                color: black;
                .shoplist-category-item {
                  background-color: #c7b199;
                }
              }
            }
          }
        }
        .menu-list {
          display: flex;
          align-items: center;
          justify-content: space-around;
          font-size: 4vw;
          list-style: none;
          padding: 0 5vw;
          li {
            display: flex;
            align-items: center;
            color: #c7b199;
            a {
              text-decoration: none;
              color: #c7b199;
            }
            .arrow {
              font-size: 3vw;
              vertical-align: bottom;
              color: #c7b199;
            }
          }
        }
        .account-info {
          display: flex;
          flex-direction: column;
          padding: 0 5vw;
          position: absolute;
          bottom: 2%;
          left: 0;
          right: 0;
          .username-mobile {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 3vw;
            font-size: 3vw;
            font-style: oblique;
            color: #c7b199;

            span {
              font-weight: bold;
              font-style: normal;
            }
          }
          .account-mobile {
            display: flex;
            padding: 2vw 2vw;
            gap: 5vw;

            button {
              font-size: 5vw;
              background-color: black;
              color: white;
              border: 1px solid black;
              padding: 2vw 5vw;
              border-radius: 5px;
            }
            .orders-mobile,
            .logout-mobile {
              flex: 1;
            }
            .login-mobile {
              flex: 1;
            }
          }
        }
      }

      .close {
        position: absolute;
        top: 10px;
        left: 10px;
        font-size: 8vw;
        color: #c7b199;
        cursor: pointer; /* Add cursor style for better usability */
      }
    }

    .main_menu {
      padding-bottom: 0;
      .menu-icons {
        justify-content: space-between;
        .left-menu {
          flex: 4;
          .mobile-menu-icon {
            display: block;
            font-size: 4vw;
          }
          .search {
            gap: 2vw;
            .search_icon {
              img {
                width: 4vw;
              }
            }
            .search-input {
              width: 18vw;
              &::placeholder {
                font-size: 3vw;
              }
            }
          }
        }
        .center-menu {
          flex: 4;
          .menu-logo-container {
            img {
              width: 12vw;
            }
          }
          .menu-list {
            display: none;
          }
        }
        .right-menu {
          flex: 0.5;
          .cart_icon {
            img {
              width: 4vw;
            }
          }
          .user_box {
            display: none;
          }
        }
      }
    }
  }
}

@media (orientation: portrait) {
  .navbar_main {
    .main_menu {
      .menu-icons {
        .center-menu {
          .menu-logo-container {
            img {
              width: 18vw;
            }
          }
        }
        .left-menu {
          gap: 5vw;
          padding-top: 5vw;
          .mobile-menu-icon {
            font-size: 5vw;
          }
          .search {
            .search_icon {
              img {
                width: 5vw;
              }
            }
          }
        }
        .right-menu {
          padding-top: 5vw;
          .cart_icon {
            img {
              width: 5vw;
            }
          }
        }
      }
    }
  }
}
