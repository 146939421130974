.return-refund{
    display: flex;
    flex-direction: column;
    padding: 5%;
    padding-top: 3%;
    gap: 10px;
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
      "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
      .return-refund_title{
        text-align: center;
        font-size: 25px;
        margin-bottom: 15px;
      }
      .return-refund_subtitle{
        font-size: 20px;
        margin-bottom: 10px;
      }
      .return-refund_text{
        font-size: 15px;
        margin-bottom: 5px;
      }
      .return-refund_second-title{
        text-align: left;
        margin-top: 20px;
      }
      ul {
        margin: 10px;
        padding: 0;
        padding-left: 5px;
        li {
          margin: 5px;
        }
      }
}