.subsribeSection {
  text-align: center;
  background-color: #f4ebe6;
  padding-top: 3vw;
}
.subsribeSection .subscribeTitle {
  font-size: 2vw;
}
.subsribeSection .subscribeText {
  font-size: 1.5vw;
}
.subsribeSection .inputSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 3vw;
  padding-bottom: 5vw;
  gap: 0.8vw;
}
.subsribeSection .inputSection .inputContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.5vw;
}
.subsribeSection .inputSection .inputContainer .error {
  position: absolute;
  top: 100%;
  left: 2%;
  font-size: 1vw;
  color: rgb(243, 43, 43);
}
@media screen and (max-width: 768px) {
  .subsribeSection .inputSection .inputContainer .error {
    font-size: 1.5vw;
  }
}
.subsribeSection .inputSection .inputContainer input {
  padding: 1.5vw 2vw;
  border: 0.2vw solid #c7b199;
}
.subsribeSection .inputSection .inputContainer input::-moz-placeholder {
  color: black;
}
.subsribeSection .inputSection .inputContainer input::placeholder {
  color: black;
}
.subsribeSection .inputSection .inputContainer input:focus::-moz-placeholder {
  color: gray;
}
.subsribeSection .inputSection .inputContainer input:focus::placeholder {
  color: gray;
}
.subsribeSection .inputSection .subscribeButton {
  padding: 1.5vw 2vw;
  background-color: black;
  color: whitesmoke;
  border: 0.2vw solid black;
}
.subsribeSection .inputSection .subscribeButton:hover {
  cursor: pointer;
  background-color: grey;
}

.aboutSection {
  background-color: #c7b199;
  padding: 1vw 5vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
}
.aboutSection span {
  font-size: 1.5vw;
}
.aboutSection .locationSection {
  display: flex;
  flex-direction: column;
  gap: 2vw;
}
.aboutSection .locationSection .locationData {
  display: flex;
  gap: 0.5vw;
}
.aboutSection .locationSection .locationData span {
  font-size: 1vw;
}
.aboutSection .contactSection {
  display: flex;
  flex-direction: column;
  gap: 2vw;
}
.aboutSection .contactSection .contactData {
  display: flex;
  gap: 0.5vw;
}
.aboutSection .contactSection .contactData span {
  font-size: 1vw;
}
.aboutSection .contactSection .contactData .contactContent {
  display: flex;
  gap: 0.5vw;
  font-size: 15px;
}
.aboutSection .socialSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2vw;
}
.aboutSection .socialSection .socialIcons {
  display: flex;
  gap: 2vw;
}
.aboutSection .socialSection .socialIcons .instagram,
.aboutSection .socialSection .socialIcons .facebook,
.aboutSection .socialSection .socialIcons .youtube {
  width: 2vw;
}
.aboutSection .policySection {
  display: flex;
  align-items: center;
  gap: 20px;
}
.aboutSection .policySection a {
  color: black;
  font-size: 12px;
  text-decoration: none;
}

.footer {
  background-color: #c7b199;
}
.footer hr {
  border-color: black;
  border-width: 0.1vw;
  margin: 0;
  padding: 0;
}
.footer .footerText {
  font-size: smaller;
  font-weight: lighter;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1vw;
}

@media screen and (max-width: 768px) {
  .subsribeSection .subscribeTitle {
    font-size: 2.5vw;
  }
  .subsribeSection .subscribeText {
    font-size: 2vw;
  }
  .aboutSection {
    flex-direction: column;
    align-items: center;
  }
  .aboutSection .locationSection,
  .aboutSection .contactSection,
  .aboutSection .socialSection {
    align-items: center;
  }
  .aboutSection .locationSection .locationData span {
    font-size: 2vw;
  }
  .aboutSection .contactSection .contactData .contactContent {
    flex-direction: column;
  }
  .aboutSection .contactSection .contactData .contactContent span {
    font-size: 12px;
  }
  .aboutSection .policySection a {
    font-size: 10px;
  }
  .aboutSection .socialSection .socialIcons .instagram,
  .aboutSection .socialSection .socialIcons .facebook,
  .aboutSection .socialSection .socialIcons .youtube {
    width: 4vw;
  }
  .aboutSection span {
    font-size: 3vw;
  }
}/*# sourceMappingURL=Footer.css.map */