.notFoundContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 85vh;
    gap: 3vw;
    button{
        padding: 1.5vw 4vw;
        cursor: pointer;
    }
}