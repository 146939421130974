

body{
    margin: 0;
    padding: 5px;
    background-color: white;
    box-sizing: border-box;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    
}

h1,h2,h3,h4,h5,h6,p{
    margin: 0;
}

h1{
  font-size: 5vw;
}

h2{
  font-size: 4vw;
}

h3{
  font-size: 3vw;
}

h4{
  font-size: 2vw;
}

h5{
  font-size: 1.5vw;
}

h6{
  font-size: 1vw;
}

p{
  font-size: 1vw;
}


@media (max-width: 768px) {
    body{
        padding: 0;
    }

    h1{
        font-size: 6vw;
    }

    h2{
        font-size: 5vw;
    }

    h3{
        font-size: 4vw;
    }

    h4{
        font-size: 3vw;
      }
      
      h5{
        font-size: 2.5vw;
      }
      
      h6{
        font-size: 2vw;
      }
      
      p{
        font-size: 2vw;
      }
}