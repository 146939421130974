.rating-chart {
  display: flex;
  flex-direction: column;
  gap: 1vw;
}

.bar-container {
  display: flex;
  align-items: center;
  gap: 1vw;
}
.bar-container .bar {
  width: 20vw;
  height: 1vw;
  border-radius: 1vw;
}/*# sourceMappingURL=RatingChart.css.map */