.checkout-container {
  padding: 2vw 4vw;
  display: flex;
  gap: 5vw;
  .error-msg {
    color: red;
    font-size: 1vw;
    &::before {
      content: "*";
      color: red;
    }
  }
  .deliveryAddress {
    flex: 1.5;
    display: flex;
    flex-direction: column;   
    gap: 1vw;
    padding: 2vw;

    input,
    textarea {
      padding: 0.5vw 1vw;
      font-size: 1.5vw;
      margin: 0;

      &::placeholder {
        font-size: 1.5vw;
      }
    }
    select {
      padding: 0.5vw 1vw;
      font-size: 1.5vw;
      margin: 0;

      &::placeholder {
        font-size: 1.5vw;
      }
    }
    .division {
      display: flex;
      flex-direction: row;
      gap: 1vw;
      div {
        width: 100%;
        input {
          user-select: text;
          -webkit-user-select: text;
          width: 100%;
          box-sizing: border-box;
        }
        select {
          width: 100%;
          box-sizing: border-box;
        }
      }
      .mobileNumber {
        display: flex;
        gap: 0.5vw;
        .country-code {
          flex: 1;
        }
        .phone {
          flex: 5;
        }
      }
    }
    .addressSection {
      display: flex;
      flex-direction: column;
      gap: 1vw;
      div {
        width: 100%;
        textarea {
          user-select: text;
          -webkit-user-select: text;
          width: 100%;
          box-sizing: border-box;
        }
      }
    }
  }
  .right-section {
    display: flex;
    flex-direction: column;
    gap: 1vw;
    max-width: 35%;
    .payment-method-section {
      display: flex;
      flex-direction: column;
      gap: 18px;
      border: 1px solid black;
      padding: 1vw 2vw;
      border-radius: 5px;
      font-size: 15px;
      .pay-method {
        display: flex;
        gap: 6px;
        font-weight: 500;
       input:disabled + label { 
        color : grey;
        cursor: not-allowed;
       }
        label {
          svg {
            vertical-align: top;
          }
          .tabby-image {
            width: 75px;
            height: auto;
          }
        }
      }
      .error-msg{
        
        max-width: 100%;
      }
      
    }
    .place-order-section {
      flex: 1;
      padding: 12px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      gap: 2vw;
      border: 1px solid black;
      border-radius: 5px;
      .order-summary {
        display: flex;
        flex-direction: column;
        gap: 0.5vw;
        .subtotal,
        .tax,
        .shipping,
        .total {
          display: flex;
          justify-content: space-between;
          span {
            font-size: 12px;
            font-weight: 500;
          }
        }
      }

      .place-order {
        display: flex;
        align-items: center;
        justify-content: center;
        // padding-top: 2vw;

        button {
          padding: 1vw 3vw;
          font-size: 16px;
          font-weight: bold;
          border-radius: 0.5vw;
          background-color: white;
          border-color: black;
          cursor: pointer;
          color: black;
          &:disabled {
            background-color: #f0f0f0; // Example: light gray background
            border-color: #ccc; // Example: light gray border
            color: darkslategrey; // Example: dark gray text
            cursor: not-allowed; // Change cursor to indicate disabled state
            opacity: 0.6; // Optional: reduce opacity to visually indicate the button is disabled
          }
        }
      }
    }
  }
}

input,
input:before,
input:after {
  -webkit-user-select: initial;
  -khtml-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;
  user-select: initial;
}

@media (orientation: portrait), screen and (max-width: 768px) {
  .checkout-container {
    flex-direction: column;
    margin-bottom: 2vw;
    padding: 3vw 4vw;
    .error-msg {
      font-size: 2vw;
    }
    .deliveryAddress {
      gap: 3vw;
      h4 {
        font-size: 5vw;
      }
      .addressSection {
        gap: 3vw;
      }
      width: 100%;
      .division {
        gap: 3vw;
        flex-direction: column;
      }
      input {
        padding: 3vw;
        font-size: 3vw;
        &::placeholder {
          font-size: 3vw;
        }
      }
      select,
      textarea {
        padding: 3vw;
        color: black;
        font-size: 3vw;
        &::placeholder {
          font-size: 3vw;
        }
      }
    }
    .right-section {
      width: 100%;
      max-width: 100%;
      .payment-method-section {
        font-size: 3vw;
        gap: 4vw;
        .pay-method{
         label{
          svg{
            width: 4vw;
            height: 4vw;
            
          }
         }
        }
      }
      .place-order-section {
        .order-summary {
          gap: 2vw;
          h4 {
            font-size: 4vw;
            text-align: center;
          }
          .subtotal,
          .tax,
          .shipping,
          .total {
            span {
              font-size: 3vw;
            }
          }
        }

        .place-order {
          button {
            border-width: 0.25vw;
            font-size: 3vw;
          }
        }
      }
    }
  }
}

input,
input:before,
input:after {
  -webkit-user-select: initial;
  -khtml-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;
  user-select: initial;
}
