
.product-control-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.5vw;
  padding: 5vw;
  label{
    font-weight: bold;
    font-size: 20px;
    @media (max-width: 768px) {
      font-size: 12px;
    }
  } 
  input,
  textarea,
  select,
  option {
    padding: 1vw;
    font-size: 1.5vw;
    border-radius: 5px;
  }
  .grouping-with-errorbox {
    display: flex;
    flex-direction: column;
    gap: 1vw;
    .error-msg {
      color: rgb(248, 78, 78);
      font-size: 1vw;
    }
    em{
      font-size: 12px;
      @media (max-width: 768px) {
        font-size: 10px;
      }
    }
  }
  .division {
    display: flex;
    gap: 1vw;
    div {
      flex: 1;
    }
  }
   .image-thumbnails{
     display: flex;
     gap: 1vw;
     flex-wrap: wrap;
     .thumbnail{
        position: relative;
        img{
          height: 12vw;
          width: auto;
          border: 1px solid black;
          cursor: grab;
        }
        .remove-button{
          position: absolute;
          top: 0;
          right: 0;
        }
     }
   }
  
  .submit-button {
    padding: 1vw;
    font-size: 1.5vw;
    cursor: pointer;
  }
}
