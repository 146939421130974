
.product-container {
  display: flex;
  gap: 5vw;
  align-items: center;
  justify-content: center;
  .product-images {
    display: flex;
    flex-direction: column;
    gap: 1vw;
    padding:5vw;
    .product-main-image-container {
        width: 25vw;
        height: auto;
        position: relative;
        .outOfStockOverlay {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.2rem;
          z-index: 1;
          pointer-events: none;
        }
       img {
        height: inherit;
        width: inherit;
        object-fit: cover;
      }
    }
    .product-secondary-images-container {
      display: flex;
      max-width: 25vw;
      overflow-x: auto;
      overflow-y: hidden;
      gap: 1vw;
      .image-container{
       display: flex;
       align-items: center;
       width: calc(21vw/5);
       height: auto;
       &.selected{
        border: 0.1vw solid black ;
        overflow: hidden;
       }
       img {
       width: inherit;
       height: inherit;
       cursor: pointer;
       object-fit: cover;
       
       }
      }
      
    }
  }
  .product-purchase-content {
    max-width: 356px;
    display: flex;
    gap: 1.5vw;
    flex-direction: column;
    padding-top: 50px;
   justify-content: center;
   .productTitle{
    font-size: 2vw;
   }
   .ratingContainer{
    display: flex;
    align-items: center;
    gap: 0.5vw;
    span{
     line-height: 0.05vw;
    }
   }
   .per-notes{
    display: flex;
    flex-direction: column;
    gap: 0.5vw;
    #note-head{
      font-weight: bold;
      text-decoration: underline;
    }
    .notes{
     font-weight: bold;
     .note-name{
      font-weight: lighter;
     }
    }

   }
    .action1{
      display: flex;
      gap: 1vw;
      align-items: flex-end;
      .addToCartBtn{
        width: fit-content;
        padding: 1vw 5vw;
        font-size: 1vw;
        cursor: pointer;
        border: none;
        background-color: black;
        color: white;
      }
      .disabledBtn{
        pointer-events: none;
        background-color: #e6ddddb6;
        color: black;
      }
      .product-quantity-wrapper{
        display: flex;
        flex-direction: column;
        gap: 0.5vw;
          .product-quantity {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-around;
             
              font-size: 1.5vw;
              gap: 1vw;
              padding: 0.5vw 2vw;
              border: 1px solid black;
              button {
                background-color: transparent;
                cursor: pointer;
                font-size: 1.5vw;
                border: none;
              }
            }
       }
      
    }
   

    .product-actions {
    
      display: flex;
      gap: 3vw;
      button {
        width: 100%;
        padding: 0.5vw 5vw;
        font-size: 1vw;
        cursor: pointer;
        border: none;
      }
      .disabledBtn{
        pointer-events: none;
        background-color: #e6ddddb6;
        color: black;
      }
      
    }
  }
}

.product-details{
  padding-top: 50px;
    .action-btns{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2vw;
    button{
        padding: 1vw 3vw;
        cursor: pointer;
        font-size: 2vw;
        border: none;
        background-color: transparent;
    }
    button.selected{
        border-bottom: .25vw solid black;
    }
    }
    .overview,.rating-n-review-section{
        padding: 3vw 5vw;
        background-color: white;
    }
    .rating-n-review-section{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5vw;
    .rating-section{
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      gap: 3vw;
      padding: 2vw;
      .rating{
       
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
   .review-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    gap:2vw;
    height: inherit;
    overflow-y: auto;
    .review-head{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .rate-btn{
        cursor: pointer;
        border: none;
        font-size: 1.5vw;
      }
    }
    
     .reviewBoxes{
        display: flex;
        flex-direction: column;
        gap: 1vw;
        width: 100%;
      .reviewBox{
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        background-color:#e6dddde0;
        padding: 1vw;
        border-radius: .5vw;
        gap: 0.5vw;
        .name{
          font-weight: bold;
        }
      }
     }
     button{
      margin-top: 1vw;
       padding: 0.5vw 3vw;
       font-size: 1.5vw;
      border: 0.1vw solid black;
       background-color: #e6dddde0;
       cursor: pointer;
     }
    
   }
    }
    
}

.overview{
  font-size: 1.25vw;
}

.loadingIndicator{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2vw;
  height: 50vh;
}


@media screen and (max-width: 768px) {
  .ratingContainer{
    gap:1vw
  }
 .product-container{
  flex-direction: column;
   padding-bottom: 10vh;
  .product-images{
    .product-main-image-container{
      width: 75vw;
      height: auto;
    }
    .product-secondary-images-container{
      max-width: 50vw;
      .image-container{
        width: calc(71vw/5) ;
      }
    }
  }
  .product-purchase-content{
    width: 75vw;
    p,h6{
      font-size: 3vw;
    }
    .productTitle{
      font-size: 4vw;
    }
    .ratingContainer{
      span{
        font-size: 3vw;
      }
    }
    .action1{
      width: 100%;
       
      .product-quantity-wrapper{
        .product-quantity{
          padding: 1vw 2vw;
          font-size: 3vw;
          flex: 1;
          button{
            font-size: 3vw;
            }
        }
      }
      .addToCartBtn{
        flex: 1;
        padding: 1.5vw 5vw;
        font-size: 3vw;
      }
    }
    .product-actions{
      padding: 1vw 0;
      button{
        font-size: 3vw;
        padding: 2vw 5vw;
      }
    }
  }
  
 }
 .product-details{
   padding: 5vw;
    .action-btns{
     button{
      font-size: 3vw;
     }
    }
    .overview{
      font-size: 3vw;
    }
    .rating-n-review-section{
      flex-direction: column;
      align-items: normal;
      .rating-section{
        flex-direction: row;
      }
      .review-section{
        p{
          font-size: 3vw;
        }
        button{
          font-size: 3vw;
        }
        .review-head{
         .rate-btn{
          font-size: 2.5vw;
          border-radius: 1vw;
         }
        }
        .reviewBoxes{
           padding: 1vw 2vw;
          .reviewBox{
            font-size: 3vw;
          }
         
        }
      }
    }
  }
}


