/* OTPInput.css */

.otp-container {
  display: flex;
  flex-direction: column;
  height: inherit;
  background-color: #f8f8ff;
  align-items: center;
  justify-content: center;
  gap: 20px;
  .fp-success{
    display: flex;
    align-items: center;
    gap: 5px;
    color: green;
    span{
        font-weight: bold;
        font-size: 14px;
        color:black;
        display: inline-block;
        vertical-align: middle;
        line-height: 1;
      }
    .fp-success-icon{
      color: green;
    }
  }
  .otp-box-container {
    display: flex;
    gap: 10px;
    .otp-input {
      width: 40px;
      height: 40px;
      text-align: center;
      font-size: 24px;
      border: 1px solid #ccc;
      border-radius: 4px;
      &:focus {
        outline: none;
        border-color: #007bff;
        box-shadow: 0 0 5px #007bff;
      }
    }
  }
  .otp-submit {
    padding: 5px 25px;
    &:hover {
      padding: 5px 25px;
      background-color: #f8f8ff;
    }
    &:disabled{
        pointer-events: none;
        background-color: grey;
        opacity: 0.5;
    }
  }
  .otp-error{
    color: red;
  }
  .otp-status{
    display: flex;
    gap: 5px;
    color: green;
    
    .otp-status-icon{
      color: green;
    }
  }
  .otp-resend-section {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    justify-content: center;
    .otp-resend-button {
      font-size: 16px;
      color: black;
     
      cursor: pointer;
      margin: 0;
      transition: color 0.3s ease-in;
      &.disabled {
        color: grey;
        cursor: not-allowed;

        span {
          font-weight: bold;
          
        }
      }
      &:not(.disabled){
        font-weight: bold;
      }
      &:not(.disabled):hover {
        color: darkslategray;
      }
    }
  }
}
