.password-change-container {
  max-width: inherit;
  margin: auto;
  padding: 20px;
  border: 1px solid #ddd;
  background-color: #f8f8ff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  .verify-success {
    display: flex;
    align-items: center;
    color: green;
    justify-content: center;
    font-style: oblique;
    gap: 5px;
    font-size: small;
    width: 100%;
    margin-bottom: 10px;
    .verify-success-icon {
      color: green;
    }
  }
  h4 {
    margin-bottom: 10px;
    font-size: large;
  }
  p {
    margin-bottom: 20px;
    font-size: small;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    strong {
      font-style: oblique;
    }
  }
  .password-change-form {
    display: flex;
    flex-direction: column;
    align-items: center;

    .form-group {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 100%;
      max-width: 250px;
      margin-bottom: 10px;
      label {
        display: block;
        margin-bottom: 5px;
        font-weight: bold;
        font-size: 12px;
        font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
      }
      input {
        width: 100%;
        padding: 8px;
        padding-right: 40px; // space for the eye icon
        border: 1px solid #ccc;
        border-radius: 4px;
      }
      .icon-eye {
        position: absolute;
        top: 40%;
        right: 10px; /* Adjust the position as needed */
        transform: translateY(-50%, -50%);
        cursor: pointer;
        font-size: 1.2em; /* Adjust the size as needed */
        color: #999; /* Adjust the color as needed */
      }
    }
    .error-message {
      color: red;
      margin-bottom: 10px;
      display: flex;
  }
  .password-submit-btn {
    padding: 5px 25px;
    border-radius: 4px;
    width: 100%;
    max-width: 200px;
    cursor: pointer;
    &:hover {
      background-color: #f8f8ff;
    }
    &:disabled {
      background-color: #ccc;
      cursor: not-allowed;
    }
  }
}
}