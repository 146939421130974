.banner-control-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.5vw;
  padding: 5vw;
  label {
    font-weight: bold;
    font-size: 20px;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
  input {
    padding: 1vw;
    font-size: 1.5vw;
  }

  .banner-selector{
    padding: 1vw;
    font-size: 16px;
    border-radius: 10px;
    font-weight: bold;
     width: 75%;
     @media (max-width: 768px) {
       font-size: 12px;
     }
     option{
      margin: 10px;
     }
  }

  .banner-container-grouping {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 1vw;
    .banner-error-msg {
      color: rgb(248, 78, 78);
      font-size: 1vw;
    }
  }
  .banner-existing-images {
    display: flex;
    flex-wrap: wrap;
    gap: 1vw;
    .banner-existing-image {
      position: relative;
      img {
        height: 15vw;
        width: auto;
        border: 1px solid black;
      }
      .banner-delete-button {
        position: absolute;
        top: 0;
        right: 0;
      }
      // .banner1-existing-images {
      //   display: flex;
      //   gap: 1vw;
      //   .banner1-existing-image {
      //     position: relative;
      //     // img{
      //     //   height: 15vw;
      //     //   width: auto;
      //     // }
      //     .banner1-delete-button {
      //       position: absolute;
      //       top: 0;
      //       right: 0;
      //     }
      //   }
      // }
    }
  }

  .banner-image-thumbnails {
    display: flex;
    gap: 1vw;
    flex-wrap: wrap;
    .banner-thumbnail {
      position: relative;
    }
    .thumbnail-remove-button {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .banner-submit-button {
    padding: 1vw;
    font-size: 1.5vw;
    cursor: pointer;
  }
}
