// Your SCSS styles
.post-container {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    
  }
  
  .post {
    width: calc(33.33% - 20px); // 33.33% width for three photos in a row with some margin
    margin: 10px; // Adjust margin as needed for spacing between photos
    
  }
  
  .post-image {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 8px; // Add border-radius or other styling as needed
  }

 
  
  .post-caption {
    margin-top: 10px;
  }
  
  .load-more-button {
   
    margin: 20px 0; // Adjust margin as needed for spacing between photos and button
    padding: 10px;
    background-color: #3498db; // Add your preferred button styling
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }

  .load-more-button:disabled{
    background-color: #cccccc;
    cursor: not-allowed;
  }

  .button-container{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (orientation : portrait)  {
    .post{
        width: calc(50% - 20px);
    }
  }
  
  // Add additional styling as needed
  