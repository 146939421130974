.addAdmin{
    display: flex;
    flex-direction: column;
    padding: 2vw 3vw;
    justify-content: center;
    .title{
        text-align: center;
    }
    .form-group{
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 1.5vw;
        padding: 5vw;
        input{
            padding: 1vw;
            font-size: 1.5vw;
        }
        .grouping-with-errorbox {
            display: flex;
            flex-direction: column;
            gap: 1vw;
            .error-msg {
              color: rgb(248, 78, 78);
              font-size: 1vw;
            }
          }
          .submit-button {
            padding: 1vw;
            font-size: 1.5vw;
            cursor: pointer;
          }
    }
}