.login {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4vw 10vw;
  height: 75vh;
}
.login .mobile-screen {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 25px;
  border: 1px solid lightgray;
  overflow: clip;
  position: relative;
}
.login .mobile-screen .background-container {
  display: flex;
  height: inherit;
  width: inherit;
}
.login .mobile-screen .background-container .banner-section {
  display: flex;
  align-items: center;
  width: 33%;
}
.login .mobile-screen .background-container .banner-section img {
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
  width: 100%;
}
.login .mobile-screen .background-container .login-section {
  background-color: whitesmoke;
  height: inherit;
  width: 30vw;
  padding: 24px;
  flex-grow: 1;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  gap: 1vw;
  border-radius: 1vh;
}
.login .mobile-screen .background-container .login-section .classic-login {
  display: flex;
  flex-direction: column;
}
.login .mobile-screen .background-container .login-section .classic-login .login-headerText {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.login .mobile-screen .background-container .login-section .classic-login .login-headerText h5 {
  font-size: 12px;
  color: rgba(12, 0, 0, 0.329);
  font-weight: bold;
}
.login .mobile-screen .background-container .login-section .classic-login .login-headerText a {
  font-size: 12px;
  font-weight: bold;
  color: black;
  text-decoration: none;
}
.login .mobile-screen .background-container .login-section .classic-login .login-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5vw;
}
.login .mobile-screen .background-container .login-section .classic-login .login-form-container input,
.login .mobile-screen .background-container .login-section .classic-login .login-form-container button {
  font-size: 16px;
  width: 100%;
  border: none;
  border-radius: 5px;
}
.login .mobile-screen .background-container .login-section .classic-login .login-form-container a {
  font-size: 1vw;
  text-decoration: none;
}
.login .mobile-screen .background-container .login-section .classic-login .login-form-container button {
  background-color: #047cf3;
  font-size: 12px;
  padding: 1vw 0.5vw;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  cursor: pointer;
  transition: all 0.5s ease;
}
.login .mobile-screen .background-container .login-section .classic-login .login-form-container button:hover {
  background-color: #0b4f94;
  color: whitesmoke;
}
.login .mobile-screen .background-container .login-section .classic-login .login-form-container .username-input-area,
.login .mobile-screen .background-container .login-section .classic-login .login-form-container .email-input-area,
.login .mobile-screen .background-container .login-section .classic-login .login-form-container .password-input-area {
  position: relative;
}
.login .mobile-screen .background-container .login-section .classic-login .login-form-container .username-input-area input,
.login .mobile-screen .background-container .login-section .classic-login .login-form-container .email-input-area input,
.login .mobile-screen .background-container .login-section .classic-login .login-form-container .password-input-area input {
  margin-bottom: 15px;
  padding: 1vw 0.5vw;
}
.login .mobile-screen .background-container .login-section .classic-login .login-form-container .username-input-area input::-moz-placeholder, .login .mobile-screen .background-container .login-section .classic-login .login-form-container .email-input-area input::-moz-placeholder, .login .mobile-screen .background-container .login-section .classic-login .login-form-container .password-input-area input::-moz-placeholder {
  font-size: 12px;
  padding: 0 0.5vw;
}
.login .mobile-screen .background-container .login-section .classic-login .login-form-container .username-input-area input::placeholder,
.login .mobile-screen .background-container .login-section .classic-login .login-form-container .email-input-area input::placeholder,
.login .mobile-screen .background-container .login-section .classic-login .login-form-container .password-input-area input::placeholder {
  font-size: 12px;
  padding: 0 0.5vw;
}
.login .mobile-screen .background-container .login-section .classic-login .login-form-container .username-input-area input:focus,
.login .mobile-screen .background-container .login-section .classic-login .login-form-container .email-input-area input:focus,
.login .mobile-screen .background-container .login-section .classic-login .login-form-container .password-input-area input:focus {
  outline: none;
}
.login .mobile-screen .background-container .login-section .classic-login .login-form-container .username-input-area .error,
.login .mobile-screen .background-container .login-section .classic-login .login-form-container .email-input-area .error,
.login .mobile-screen .background-container .login-section .classic-login .login-form-container .password-input-area .error {
  color: rgb(240, 82, 82);
  font-size: 10px;
}
.login .mobile-screen .background-container .login-section .classic-login .login-form-container .username-input-area .error-username,
.login .mobile-screen .background-container .login-section .classic-login .login-form-container .username-input-area .error-email,
.login .mobile-screen .background-container .login-section .classic-login .login-form-container .username-input-area .error-password,
.login .mobile-screen .background-container .login-section .classic-login .login-form-container .email-input-area .error-username,
.login .mobile-screen .background-container .login-section .classic-login .login-form-container .email-input-area .error-email,
.login .mobile-screen .background-container .login-section .classic-login .login-form-container .email-input-area .error-password,
.login .mobile-screen .background-container .login-section .classic-login .login-form-container .password-input-area .error-username,
.login .mobile-screen .background-container .login-section .classic-login .login-form-container .password-input-area .error-email,
.login .mobile-screen .background-container .login-section .classic-login .login-form-container .password-input-area .error-password {
  position: absolute;
  bottom: 2px;
  left: 5px;
}
.login .mobile-screen .background-container .login-section .classic-login .forgot-password {
  margin: 0;
  padding: 0;
  font-size: small;
}
.login .mobile-screen .background-container .login-section hr {
  margin: 0;
  padding: 0;
}
.login .mobile-screen .background-container .login-section .social-login-section {
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding-top: 5px;
}
.login .mobile-screen .background-container .login-section .social-login-section .social-login img {
  width: 4vw;
  cursor: pointer;
}

@media (orientation: portrait) and (max-width: 767px) {
  .login .mobile-screen {
    height: 70vh;
    width: 38vh;
  }
  .login .mobile-screen .background-container {
    flex-direction: column;
  }
  .login .mobile-screen .background-container hr {
    display: none;
  }
  .login .mobile-screen .background-container .banner-section {
    width: 100%;
    max-height: -moz-fit-content;
    max-height: fit-content;
  }
  .login .mobile-screen .background-container .banner-section img {
    width: 100%;
    height: 20vh;
  }
  .login .mobile-screen .background-container .login-section {
    height: 35vh;
    width: 100%;
    padding: 4vw 2vw;
    gap: 4vh;
    position: relative;
    justify-content: flex-end;
  }
  .login .mobile-screen .background-container .login-section .classic-login {
    position: absolute;
    top: -4px;
    left: 20px;
    right: 20px;
    bottom: 100px;
    background-color: whitesmoke;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    padding: 15px;
  }
  .login .mobile-screen .background-container .login-section .classic-login .login-headerText h5 {
    font-size: 3vw;
  }
  .login .mobile-screen .background-container .login-section .classic-login .login-headerText a {
    font-size: 3vw;
  }
  .login .mobile-screen .background-container .login-section .classic-login .login-form-container {
    display: flex;
    gap: 1vh;
    padding-top: 10px;
  }
  .login .mobile-screen .background-container .login-section .classic-login .login-form-container input,
  .login .mobile-screen .background-container .login-section .classic-login .login-form-container button {
    font-size: 2vh;
    padding: 1vh 0.5vh;
  }
  .login .mobile-screen .background-container .login-section .classic-login .login-form-container button {
    margin-top: 3vh;
  }
  .login .mobile-screen .background-container .login-section .classic-login .login-form-container a {
    font-size: 1.5vh;
  }
  .login .mobile-screen .background-container .login-section .classic-login .login-form-container input::-moz-placeholder {
    font-size: 2vh;
  }
  .login .mobile-screen .background-container .login-section .classic-login .login-form-container input::placeholder {
    font-size: 2vh;
  }
  .login .mobile-screen .background-container .login-section .classic-login .login-form-container .error {
    font-size: 2vw;
  }
  .login .mobile-screen .background-container .login-section .social-login-section {
    padding-bottom: 10px;
    display: flex;
    align-items: center;
  }
  .login .mobile-screen .background-container .login-section .social-login-section p {
    font-size: 10px;
  }
  .login .mobile-screen .background-container .login-section .social-login-section .social-login img {
    width: 40px;
  }
}
@media (min-width: 900px) {
  .login {
    padding: 5px 10vw;
  }
  .login .mobile-screen {
    height: -moz-max-content;
    height: max-content;
  }
  .login .mobile-screen .background-container .banner-section {
    width: 60%;
    height: auto;
  }
  .login .mobile-screen .background-container .login-section {
    height: auto;
    width: auto;
  }
  .login .mobile-screen .background-container .login-section .classic-login .login-headerText {
    padding: 1vw;
  }
  .login .mobile-screen .background-container .login-section .classic-login .login-headerText h5,
  .login .mobile-screen .background-container .login-section .classic-login .login-headerText a {
    font-size: medium;
  }
  .login .mobile-screen .background-container .login-section .classic-login .login-form-container {
    gap: 1vw;
  }
  .login .mobile-screen .background-container .login-section .classic-login .login-form-container .email-input-area input {
    padding-left: 1vw;
    padding-right: 1vw;
  }
  .login .mobile-screen .background-container .login-section .classic-login .login-form-container .password-input-area input {
    padding-left: 1vw;
    padding-right: 1vw;
  }
}/*# sourceMappingURL=Login.css.map */