.paymentSuccessContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 25vh;
  gap: 2vh;
  background-color: #E8F5E9;
  color: #388E3C;
  border-radius: 8px;
  height: 75vh;
}/*# sourceMappingURL=PaymentSuccess.css.map */