.paymentFailureContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 25vh;
  gap: 2vh;
  background-color: #ffebee;
  color: #d32f2f; /* Dark Red */
  border-radius: 8px;
  height: 75vh;
}
