.subsribeSection {
  text-align: center;
  background-color: #f4ebe6;
  padding-top: 3vw;
  .subscribeTitle{
    font-size: 2vw;
  }
  .subscribeText {
    font-size: 1.5vw;
  }
  .inputSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 3vw;
    padding-bottom: 5vw;
    gap: 0.8vw;
    .inputContainer {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 0.5vw;
      .error {
        position: absolute;
        top: 100%;
        left: 2%;
        font-size: 1vw;
        color: rgb(243, 43, 43);
        @media screen and (max-width: 768px) {
          font-size: 1.5vw;
        }
      }
      input {
        padding: 1.5vw 2vw;
        border: 0.2vw solid #c7b199;
        &::placeholder {
          color: black;
        }
        &:focus::placeholder {
          color: gray;
        }
      }
    }

    .subscribeButton {
      padding: 1.5vw 2vw;
      background-color: black;
      color: whitesmoke;
      border: 0.2vw solid black;
      &:hover {
        cursor: pointer;
        background-color: grey;
      }
    }
  }
}
.aboutSection {
  background-color: #c7b199;
  padding: 1vw 5vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
  span {
    font-size: 1.5vw;
  }
  .locationSection {
    display: flex;
    flex-direction: column;
    gap: 2vw;
    .locationData {
      display: flex;
      gap: 0.5vw;
      span{
        font-size: 1vw;
      }
    }
  }
  .contactSection {
    display: flex;
    flex-direction: column;
    gap: 2vw;
    .contactData {
      display: flex;
      gap: 0.5vw;
      span{
        font-size: 1vw;
      }
      .contactContent{
        display: flex;
        gap: 0.5vw;
        font-size: 15px;
      }
    }
  }
  .socialSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2vw;
    .socialIcons {
      display: flex;
      
      gap: 2vw;
      .instagram,
      .facebook,
      .youtube {
        width: 2vw;
      }
    }
  }
  .policySection{
    display: flex;
    align-items: center;
    gap: 20px;
    a{
      color: black;
      font-size: 12px;
      text-decoration: none;
    }
  }
}
.footer {
  background-color: #c7b199;
  hr {
    border-color: black;
    border-width: 0.1vw;
    margin: 0;
    padding: 0;
  }
  .footerText {
    font-size: smaller;
    font-weight: lighter;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 1vw;
  }
}

@media screen and (max-width: 768px) {
  .subsribeSection {
    .subscribeTitle{
      font-size: 2.5vw;
    }
    .subscribeText {
      font-size: 2vw;
    }
  }
  .aboutSection {
    flex-direction: column;
    align-items: center;
    .locationSection,
    .contactSection,
    .socialSection {
      align-items: center;
    }
   .locationSection{
    .locationData{
      span{
        font-size: 2vw;
      }
    }
   }
   .contactSection{
    .contactData{
      .contactContent{
        flex-direction: column;
        span{
          font-size: 12px;
        }
      }
    }
  }
  .policySection{
    a{
      font-size: 10px;
    }
  }
  .socialSection{
    .socialIcons{
      .instagram,
      .facebook,
      .youtube{
        width: 4vw;
      }
    }
  }
    span {
      font-size: 3vw;
    }
  }
}
