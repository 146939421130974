$primary: #047cf3;
$secondary: #f2f2f2;
.login {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4vw 10vw;
  height: 75vh;
  .mobile-screen {
    width: 100%;
    height: fit-content;
    border-radius: 25px;
    border: 1px solid lightgray;
    overflow: clip;
    position: relative;
    .background-container {
      display: flex;
      height: inherit;
      width: inherit;

      .banner-section {
        display: flex;
        align-items: center;
        width: 33%;
        img {
          object-fit: cover;
          height: 100%;
          width: 100%;
        }
      }
      .login-section {
        background-color: whitesmoke;
        height: inherit;
        width: 30vw;
        padding: 24px;
        flex-grow: 1;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
        display: flex;
        flex-direction: column;
        gap: 1vw;
        border-radius: 1vh;
        .classic-login {
          display: flex;
          flex-direction: column;
          .login-headerText {
            display: flex;
            align-items: center;
            justify-content: space-between;
            h5 {
              font-size: 12px;
              color: rgba(12, 0, 0, 0.329);
              font-weight: bold;
            }
            a {
              font-size: 12px;
              font-weight: bold;
              color: black;
              text-decoration: none;
            }
          }
          .login-form-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 0.5vw;

            input,
            button {
              font-size: 16px;
              width: 100%;

              border: none;
              border-radius: 5px;
            }
            a {
              font-size: 1vw;
              text-decoration: none;
            }
            button {
              background-color: #047cf3;
              font-size: 12px;
              padding: 1vw 0.5vw;
              font-family: "Franklin Gothic Medium", "Arial Narrow", Arial,
                sans-serif;
              cursor: pointer;
              transition: all 0.5s ease;
              &:hover {
                background-color: #0b4f94;
                color: whitesmoke;
              }
            }
            .username-input-area,
            .email-input-area,
            .password-input-area {
              position: relative;
              input {
                margin-bottom: 15px;
                padding: 1vw 0.5vw;
                &::placeholder {
                  font-size: 12px;
                  padding: 0 0.5vw;
                }
                &:focus {
                  outline: none;
                }
              }
              .error {
                color: rgb(240, 82, 82);
                font-size: 10px;
              }
              .error-username,
              .error-email,
              .error-password {
                position: absolute;
                bottom: 2px;
                left: 5px;
              }
            }
          }
          .forgot-password {
            margin: 0;
            padding: 0;
            font-size: small;
          }
        }

        hr {
          margin: 0;
          padding: 0;
        }
        .social-login-section {
          background-color: whitesmoke;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 5px;
          padding-top: 5px;
          .social-login {
            img {
              width: 4vw;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

@media (orientation: portrait) and (max-width: 767px) {
  .login{
    .mobile-screen {
      height: 70vh;
      width: 38vh;
      .background-container {
        hr {
          display: none;
        }
        flex-direction: column;
        .banner-section {
          width: 100%;
          max-height: fit-content;
          img {
            width: 100%;
            height: 20vh;
          }
        }
        .login-section {
          height: 35vh;
          width: 100%;
          padding: 4vw 2vw;
          gap: 4vh;
          position: relative;
          justify-content: flex-end;
          .classic-login {
            position: absolute;
            top: -4px;
            left: 20px;
            right: 20px;
            bottom: 100px;
            background-color: whitesmoke;
            box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
            border-radius: 10px;
            padding: 15px;
            .login-headerText {
              h5 {
                font-size: 3vw;
              }
              a {
                font-size: 3vw;
              }
            }
            .login-form-container {
              display: flex;
              input,
              button {
                font-size: 2vh;
                padding: 1vh 0.5vh;
              }
              button {
                margin-top: 3vh;
              }
              a {
                font-size: 1.5vh;
              }
              input {
                &::placeholder {
                  font-size: 2vh;
                }
              }
              .error {
                font-size: 2vw;
              }
              gap: 1vh;
              padding-top: 10px;
            }
          }
          .social-login-section {
            padding-bottom: 10px;
            display: flex;
            align-items: center;
            p {
              font-size: 10px;
            }
            .social-login {
              img {
                width: 40px;
              }
            }
          }
        }
      }
    }
  }
}
@media (min-width: 900px) {
  .login {
    padding: 5px 10vw;
    .mobile-screen {
      height: max-content;
      .background-container {
        .banner-section {
          width: 60%;
          height: auto;
        }
        .login-section {
          height: auto;
          width: auto;
          .classic-login {
            .login-headerText {
              padding: 1vw;
              h5,
              a {
                font-size: medium;
              }
            }

            .login-form-container {
              gap: 1vw;
              .email-input-area {
                input {
                  padding-left: 1vw;
                  padding-right: 1vw;
                }
              }

              .password-input-area {
                input {
                  padding-left: 1vw;
                  padding-right: 1vw;
                }
              }
            }
          }
        }
      }
    }
  }
}
