/* OTPInput.css */
.otp-container {
  display: flex;
  flex-direction: column;
  height: inherit;
  background-color: #f8f8ff;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.otp-container .fp-success {
  display: flex;
  align-items: center;
  gap: 5px;
  color: green;
}
.otp-container .fp-success span {
  font-weight: bold;
  font-size: 14px;
  color: black;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
}
.otp-container .fp-success .fp-success-icon {
  color: green;
}
.otp-container .otp-box-container {
  display: flex;
  gap: 10px;
}
.otp-container .otp-box-container .otp-input {
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 24px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.otp-container .otp-box-container .otp-input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 5px #007bff;
}
.otp-container .otp-submit {
  padding: 5px 25px;
}
.otp-container .otp-submit:hover {
  padding: 5px 25px;
  background-color: #f8f8ff;
}
.otp-container .otp-submit:disabled {
  pointer-events: none;
  background-color: grey;
  opacity: 0.5;
}
.otp-container .otp-error {
  color: red;
}
.otp-container .otp-status {
  display: flex;
  gap: 5px;
  color: green;
}
.otp-container .otp-status .otp-status-icon {
  color: green;
}
.otp-container .otp-resend-section {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  justify-content: center;
}
.otp-container .otp-resend-section .otp-resend-button {
  font-size: 16px;
  color: black;
  cursor: pointer;
  margin: 0;
  transition: color 0.3s ease-in;
}
.otp-container .otp-resend-section .otp-resend-button.disabled {
  color: grey;
  cursor: not-allowed;
}
.otp-container .otp-resend-section .otp-resend-button.disabled span {
  font-weight: bold;
}
.otp-container .otp-resend-section .otp-resend-button:not(.disabled) {
  font-weight: bold;
}
.otp-container .otp-resend-section .otp-resend-button:not(.disabled):hover {
  color: darkslategray;
}/*# sourceMappingURL=OTPInput.css.map */