.home {
    background-color: white;
  }
  
  .bannerContainer {
    position: relative;
    margin-bottom: -1vw;
    direction: ltr;
    width: 100%;
    height:100vh;
    
  }
  
  .bannerImage1,.bannerImage2,.bannerImage3,.bannerImage4,.bannerImage5,.bannerImage6,.bannerImage7 {
    width: 100%;
    height: 100vh;
    object-fit:cover;
    object-position: center center;
  }
  
  .buttonContainer {
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .bannerButton {
    padding: 10px 5vw;
    background-color: #c7b199;
    color: #251a02;
    border: none;
    font-weight: bold;
    cursor: pointer;
  }
  
  .offerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 0;
    font-size: large;
    background-color: #c7b199;
  }
  .offerTitle {
    margin-bottom: 1rem;
  }
  .offerText {
    text-align: center;
  }
  .splCollectionsSection {
    padding: 2vw;
  }
  .splCollectionContainer {
    position: relative;
    padding: 3vw;
  }
  .logo2 {
    width: 15vw;
    opacity: 0.4;
  }
  .logo2Container {
    text-align: center;
  }
  
  .splCollectionTitle {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2vw;
  }
  
  .splCollectionCardContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3vw;
    gap: 4vw;
    flex-wrap: wrap;
  }
  .splCollectionCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5vw;
    font-size: 1.5vw;
    cursor: pointer;
    position: relative;
  }

  .outOfStock {
    pointer-events: none;
    opacity: 0.6; /* Adjust as needed */
  }

  .outOfStockOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    z-index: 1;
  }
  .splCollectionImageCard {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: .5vw;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
    overflow: hidden;
  }
  .splCollectionImage {
    width: 18vw;
    height: 21vw;
    object-fit: cover;
  }

  .splCollectionItemPriceContainer{
    display: flex;
    gap: 1vw;
    align-items: center;
  }
  
  .stockImageContainer {
    display: flex;
    flex-direction: row;
    font-size: 0;
  }
  .stockImageContainer img {
    margin: 0;
    padding: 0;
    width: 50%;
    object-fit: cover;
    box-sizing: border-box;
  }
  
  .stockImageContainer2 img {
    width: 100%;
  }
  
  .quoteContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 2vw;
    padding-bottom: 1vw;
  }
  .quoteTitle {
    font-size: 2vw;
  }
  .quoteText {
    font-size: 1.5vw;
  }
  .trendingTitle {
    text-align: center;
    font-size: 2vw;
    margin-bottom: 3vw;
  }
  .whatsNewContainer {
    position: relative;
  }
  .whatsNewTitle {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2vw;
  }
  
  .whatsNewCardContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3vw;
    gap: 4vw;
    flex-wrap: wrap;
  }
  .whatsNewCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5vw;
    font-size: 1.5vw;
    cursor: pointer;
    position: relative;
  }
  
  .whatsNewImageCard {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: .5vw;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
    overflow: hidden;
  }
  
  .whatsNewImage {
    width: 18vw;
    height: 21vw;
    object-fit: cover;
  }

  .whatsNewItemPriceContainer{
    display: flex;
    gap: 1vw;
    align-items: center;
  }
  
  .collectionContainer {
    padding: 5vw 0;
    position: relative;
  }
  
  .collectionTitle {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2vw;
    
  }
  
  .collectionCardContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3vw;
    gap: 4vw;
    flex-wrap: wrap;
  }
  .collectionCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5vw;
    font-size: 1.5vw;
    cursor: pointer;
  }
  
  .collectionImageCard {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: .5vw;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
    overflow: hidden;
  }
  
  .collectionImage {
    width: 18vw;
    height: 21vw;
    object-fit: cover;
  }
  .collectionItemPriceContainer{
    display: flex;
    gap: 1vw;
    align-items: center;
  }
  .brands {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 3vw 6vw 1vw 6vw;
    background-color: #c7b199;
  }
  .brand-wrapper {
    display: flex;
    flex-basis: 20%;
    max-width: 20%;
    align-items: center;
    justify-content: center;
    padding-bottom: 2vw;
    overflow: hidden;
  }
  .brand-wrapper img {
    max-width: 12vw;
    object-fit: cover;
  }
  .brand-wrapper .hexa-modkila,.tysir{
    width: 7vw;
  }
  .highlightItemContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 10vw 0;
    gap: 10vw;
  }
  .highlightItemTitleSection {
    display: flex;
    flex-direction: column;
    gap: 3vw;
  }
  .highlightItemTitle {
    width: 15vw;
  }
  .highlightItemQuote {
    width: 15vw;
  }
  .highlightItemImage {
    width: 40vw;
  }

  .subsribeSection {
    text-align: center;
  }
  .inputSection {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3vw 0;
    gap: 0.8vw;
  }
  .inputSection input {
    padding: 1.5vw 2vw;
    border: 0.2vw solid #c7b199;
  }
  .inputSection input::placeholder {
    color: black;
  }
  .inputSection input:focus::placeholder {
    color: gray;
  }
  .subscribeButton {
    padding: 1.5vw 2vw;
    background-color: black;
    color: whitesmoke;
    border: 0.2vw solid black;
  }
  .subscribeButton:hover {
    cursor: pointer;
    background-color: grey;
  }
  /* .aboutSection {
    background-color: #c7b199;
    padding: 5vw;
    display: flex;
    justify-content: center;
    gap: 7vw;
  } */
  
  .locationSection {
    display: flex;
    flex-direction: column;
    gap: 2vw;
  }
  
  .locationData {
    display: flex;
    gap: 0.5vw;
  }
  
  /* .contactSection {
    display: flex;
    flex-direction: column;
    gap: 2vw;
  }
  .contactData {
    display: flex;
    gap: 0.5vw;
  } */
  
    /* .locationContent,
    .contactContent {
      display: flex;
      flex-direction: column;
    } */
  
  .socialSection {
    display: flex;
    flex-direction: column;
    gap: 2vw;
  }
  .socialIcons {
    display: flex;
    gap: 2vw;
  }
  
  .instagram,
  .facebook,
  .youtube {
    width: 3vw;
  }
  
  /* .footer {
    background-color: #c7b199;
    padding: 2vw 1vw;
  }
  .footer hr {
    border-color: black;
    border-width: 0.1vw;
  }
  .footerText {
    font-size: smaller;
    font-weight: lighter;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  } */

  .splCollectionItemOriPrice,.whatsNewItemOriPrice,.collectionItemOriPrice{
    font-size: 1.25vw;
   }
  
  @media (max-width: 768px) {
    .bannerContainer{
      height: 90vw;
    }
    .buttonContainer {
      display: none;
    }
    .bannerWrapper {
      position: relative;
    }
    .offerContainer {
      position: absolute;
      top: 85%;
      left: 35%;
      transform: translate(-35%, -50%);
      font-size: 60%;
      color: white;
      align-items: flex-start;
      padding: 0.5vw;
      background-color: transparent;
    }
    .offerTitle {
      margin-bottom: 2.5vw;
      font-size: 4.5vw;
    }
    .offerText {
      text-align: left;
      font-size: 2.5vw;
      
    }
    .logo2{
      width: 25vw;
    }
    .splCollectionTitle {
      font-size: 3.5vw;
      
    }
    .quoteTitle {
      font-size: 3vw;
    }
    .quoteText {
      font-size: 2vw;
    }
    .trendingTitle {
      font-size: 3vw;
    }
    .splCollectionCardContainer {
      padding: 3vw 0;
      gap: 5vw;
    }
   .splCollectionImage{
      width: 42vw;
      height: 49vw;
    }
    .splCollectionCard{
      gap: 1.5vw;
      font-size: 2.5vw;
    }
    .splCollectionImageCard{
    box-shadow: none;
    border: .5px solid  #c7b199;
    border-radius: 5vw;
    overflow: hidden;
    }
    .whatsNewTitle{
      font-size: 3.5vw;
      
    }
   .whatsNewCardContainer{
    gap: 5vw;
    padding: 3vw 0;
   }
   .whatsNewImage{
    width: 42vw;
    height: 49vw;
   }
   .whatsNewCard{
    gap: 1.5vw;
    font-size: 2.5vw;
   }
   .whatsNewImageCard{
    box-shadow: none;
    border: .5px solid  #c7b199;
    border-radius: 5vw;
    overflow: hidden;
   }

   .collectionCardContainer{
    gap: 5vw;
    padding: 3vw 0;
   }
   .collectionTitle{
    font-size: 3.5vw;
    
   }
   .collectionImage{
    width: 42vw;
    height: 49vw;
   }
   .collectionCard{
    gap: 1.5vw;
    font-size: 2.5vw;
   }
   .collectionImageCard{
    box-shadow: none;
    border: .5px solid  #c7b199;
    border-radius: 5vw;
    overflow: hidden;
   }
   .splCollectionItemOriPrice,.whatsNewItemOriPrice,.collectionItemOriPrice{
    font-size: 2.25vw;
   }
  }
  .giftImg{
    width: 100%;
    height: auto;
    object-fit: cover;
  }

@media (orientation:portrait){
  .bannerImage1{
    height: 90vw;
    object-fit:cover;
    object-position: left center; 
  }
  .bannerImage2,.bannerImage3,.bannerImage4,.bannerImage5,.bannerImage6,.bannerImage7{
    height: 90vw;
    object-fit:cover;
    object-position: center center;
  }
  
}
  