.CancellationPolicy {
  display: flex;
  flex-direction: column;
  padding: 5%;
  padding-top: 3%;
  gap: 10px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  height: 50vh;
}
.CancellationPolicy .CancellationPolicy_title {
  text-align: center;
  font-size: 25px;
  margin-bottom: 15px;
}
.CancellationPolicy .CancellationPolicy_text {
  font-size: 15px;
  margin-bottom: 5px;
}
.CancellationPolicy .CancellationPolicy_text a {
  text-decoration: none;
  font-weight: bold;
}/*# sourceMappingURL=CancellationPolicy.css.map */