.checkout-container {
  padding: 2vw 4vw;
  display: flex;
  gap: 5vw;
}
.checkout-container .error-msg {
  color: red;
  font-size: 1vw;
}
.checkout-container .error-msg::before {
  content: "*";
  color: red;
}
.checkout-container .deliveryAddress {
  flex: 1.5;
  display: flex;
  flex-direction: column;
  gap: 1vw;
  padding: 2vw;
}
.checkout-container .deliveryAddress input,
.checkout-container .deliveryAddress textarea {
  padding: 0.5vw 1vw;
  font-size: 1.5vw;
  margin: 0;
}
.checkout-container .deliveryAddress input::-moz-placeholder, .checkout-container .deliveryAddress textarea::-moz-placeholder {
  font-size: 1.5vw;
}
.checkout-container .deliveryAddress input::placeholder,
.checkout-container .deliveryAddress textarea::placeholder {
  font-size: 1.5vw;
}
.checkout-container .deliveryAddress select {
  padding: 0.5vw 1vw;
  font-size: 1.5vw;
  margin: 0;
}
.checkout-container .deliveryAddress select::-moz-placeholder {
  font-size: 1.5vw;
}
.checkout-container .deliveryAddress select::placeholder {
  font-size: 1.5vw;
}
.checkout-container .deliveryAddress .division {
  display: flex;
  flex-direction: row;
  gap: 1vw;
}
.checkout-container .deliveryAddress .division div {
  width: 100%;
}
.checkout-container .deliveryAddress .division div input {
  -moz-user-select: text;
       user-select: text;
  -webkit-user-select: text;
  width: 100%;
  box-sizing: border-box;
}
.checkout-container .deliveryAddress .division div select {
  width: 100%;
  box-sizing: border-box;
}
.checkout-container .deliveryAddress .division .mobileNumber {
  display: flex;
  gap: 0.5vw;
}
.checkout-container .deliveryAddress .division .mobileNumber .country-code {
  flex: 1;
}
.checkout-container .deliveryAddress .division .mobileNumber .phone {
  flex: 5;
}
.checkout-container .deliveryAddress .addressSection {
  display: flex;
  flex-direction: column;
  gap: 1vw;
}
.checkout-container .deliveryAddress .addressSection div {
  width: 100%;
}
.checkout-container .deliveryAddress .addressSection div textarea {
  -moz-user-select: text;
       user-select: text;
  -webkit-user-select: text;
  width: 100%;
  box-sizing: border-box;
}
.checkout-container .right-section {
  display: flex;
  flex-direction: column;
  gap: 1vw;
  max-width: 35%;
}
.checkout-container .right-section .payment-method-section {
  display: flex;
  flex-direction: column;
  gap: 18px;
  border: 1px solid black;
  padding: 1vw 2vw;
  border-radius: 5px;
  font-size: 15px;
}
.checkout-container .right-section .payment-method-section .pay-method {
  display: flex;
  gap: 6px;
  font-weight: 500;
}
.checkout-container .right-section .payment-method-section .pay-method input:disabled + label {
  color: grey;
  cursor: not-allowed;
}
.checkout-container .right-section .payment-method-section .pay-method label svg {
  vertical-align: top;
}
.checkout-container .right-section .payment-method-section .pay-method label .tabby-image {
  width: 75px;
  height: auto;
}
.checkout-container .right-section .payment-method-section .error-msg {
  max-width: 100%;
}
.checkout-container .right-section .place-order-section {
  flex: 1;
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 2vw;
  border: 1px solid black;
  border-radius: 5px;
}
.checkout-container .right-section .place-order-section .order-summary {
  display: flex;
  flex-direction: column;
  gap: 0.5vw;
}
.checkout-container .right-section .place-order-section .order-summary .subtotal,
.checkout-container .right-section .place-order-section .order-summary .tax,
.checkout-container .right-section .place-order-section .order-summary .shipping,
.checkout-container .right-section .place-order-section .order-summary .total {
  display: flex;
  justify-content: space-between;
}
.checkout-container .right-section .place-order-section .order-summary .subtotal span,
.checkout-container .right-section .place-order-section .order-summary .tax span,
.checkout-container .right-section .place-order-section .order-summary .shipping span,
.checkout-container .right-section .place-order-section .order-summary .total span {
  font-size: 12px;
  font-weight: 500;
}
.checkout-container .right-section .place-order-section .place-order {
  display: flex;
  align-items: center;
  justify-content: center;
}
.checkout-container .right-section .place-order-section .place-order button {
  padding: 1vw 3vw;
  font-size: 16px;
  font-weight: bold;
  border-radius: 0.5vw;
  background-color: white;
  border-color: black;
  cursor: pointer;
  color: black;
}
.checkout-container .right-section .place-order-section .place-order button:disabled {
  background-color: #f0f0f0;
  border-color: #ccc;
  color: darkslategrey;
  cursor: not-allowed;
  opacity: 0.6;
}

input,
input:before,
input:after {
  -webkit-user-select: initial;
  -moz-user-select: initial;
  user-select: initial;
}

@media (orientation: portrait), screen and (max-width: 768px) {
  .checkout-container {
    flex-direction: column;
    margin-bottom: 2vw;
    padding: 3vw 4vw;
  }
  .checkout-container .error-msg {
    font-size: 2vw;
  }
  .checkout-container .deliveryAddress {
    gap: 3vw;
    width: 100%;
  }
  .checkout-container .deliveryAddress h4 {
    font-size: 5vw;
  }
  .checkout-container .deliveryAddress .addressSection {
    gap: 3vw;
  }
  .checkout-container .deliveryAddress .division {
    gap: 3vw;
    flex-direction: column;
  }
  .checkout-container .deliveryAddress input {
    padding: 3vw;
    font-size: 3vw;
  }
  .checkout-container .deliveryAddress input::-moz-placeholder {
    font-size: 3vw;
  }
  .checkout-container .deliveryAddress input::placeholder {
    font-size: 3vw;
  }
  .checkout-container .deliveryAddress select,
  .checkout-container .deliveryAddress textarea {
    padding: 3vw;
    color: black;
    font-size: 3vw;
  }
  .checkout-container .deliveryAddress select::-moz-placeholder, .checkout-container .deliveryAddress textarea::-moz-placeholder {
    font-size: 3vw;
  }
  .checkout-container .deliveryAddress select::placeholder,
  .checkout-container .deliveryAddress textarea::placeholder {
    font-size: 3vw;
  }
  .checkout-container .right-section {
    width: 100%;
    max-width: 100%;
  }
  .checkout-container .right-section .payment-method-section {
    font-size: 3vw;
    gap: 4vw;
  }
  .checkout-container .right-section .payment-method-section .pay-method label svg {
    width: 4vw;
    height: 4vw;
  }
  .checkout-container .right-section .place-order-section .order-summary {
    gap: 2vw;
  }
  .checkout-container .right-section .place-order-section .order-summary h4 {
    font-size: 4vw;
    text-align: center;
  }
  .checkout-container .right-section .place-order-section .order-summary .subtotal span,
  .checkout-container .right-section .place-order-section .order-summary .tax span,
  .checkout-container .right-section .place-order-section .order-summary .shipping span,
  .checkout-container .right-section .place-order-section .order-summary .total span {
    font-size: 3vw;
  }
  .checkout-container .right-section .place-order-section .place-order button {
    border-width: 0.25vw;
    font-size: 3vw;
  }
}
input,
input:before,
input:after {
  -webkit-user-select: initial;
  -moz-user-select: initial;
  user-select: initial;
}/*# sourceMappingURL=Checkout.css.map */