.about {
  display: flex;
  flex-direction: column;
}
.about img {
  width: auto;
}

.loadingIndicator {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2vw;
  height: 50vh;
}/*# sourceMappingURL=About.css.map */