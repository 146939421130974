.shippingPolicy {
  display: flex;
  flex-direction: column;
  padding: 5%;
  padding-top: 3%;
  gap: 10px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}
.shippingPolicy .shippingPolicy_title {
  text-align: center;
  font-size: 25px;
  margin-bottom: 15px;
}
.shippingPolicy .shippingPolicy_subtitle {
  font-size: 20px;
  margin-bottom: 10px;
  text-decoration: underline;
}
.shippingPolicy .shippingPolicy_text {
  font-size: 15px;
  margin-bottom: 5px;
}
.shippingPolicy table {
  width: max(350px, 50%);
  border: 1px solid #ddd;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 15px;
}
.shippingPolicy table thead,
.shippingPolicy table tbody,
.shippingPolicy table th,
.shippingPolicy table td {
  border: 1px solid #ddd;
}
.shippingPolicy table th {
  text-align: left;
  font-weight: bold;
  padding: 10px 20px;
  padding-right: 35px;
}
.shippingPolicy table td {
  padding: 10px 20px;
  padding-right: 35px;
  text-align: left;
  font-size: 15px;
  font-weight: normal;
  vertical-align: top;
}/*# sourceMappingURL=ShippingPolicy.css.map */