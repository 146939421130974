.cart-container {
  padding: 2vw;
  //    background-color: wheat;

  .empty-cart {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50vh;
  }

  .cart-divisions {
    display: flex;
    .division1 {
      flex: 2;
      max-height: 70vh;
      overflow-y: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      .cart-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: whitesmoke;
        padding: 1vw 2vw;
      }
      .cart-products-container {
        display: flex;
        flex-direction: column;
        gap: 1vw;

        .cart-product {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 2vw;

          .product-details {
            display: flex;
            flex-direction: column;
            gap: 0.5vw;
            max-width: 10vw;
            flex-wrap: wrap;
            .image-container {
              width: 10vw;
              height: auto;
              border: 0.1vw solid whitesmoke;
              cursor: pointer;
              img {
                width: inherit;
                height: inherit;
                object-fit: cover;
              }
            }
             
            .price-in-cart {
              font-style: oblique;
            }

            .remove {
              cursor: pointer;
              color: red;
            }
          }
          .quantity-handler {
            display: flex;
            align-items: center;
            gap: 1vw;
            padding: 2vw;
            border: 0.2vw solid whitesmoke;
            button {
              font-size: 1.5vw;
              border: none;
              background-color: transparent;
              cursor: pointer;
            }
          }
        }
      }
    }
    hr {
      border: 1px solid lightgray;
    }
    .division2 {
      flex: 1;
      .cart-total-section {
        padding: 5vw;
        display: flex;
        flex-direction: column;
        gap: 1vw;
        .subtotal,
        .tax,
        .total {
          display: flex;
          justify-content: space-between;
        }
      }
      .checkout-section {
        padding: 2vw;
        display: flex;
        justify-content: flex-end;
        button {
          padding: 1vh 3vw;
          font-size: 2vw;
          border-radius: 5px;
          border: 0.3vw solid whitesmoke;
          background-color: white;
          cursor: pointer;
          font-weight: bold;
        }
      }
      .ship-info{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1vw;
       }
    }
  }

  
}

@media (max-width: 768px) {
  .cart-container {
    .cart-divisions {
      margin: 4vw;
      padding: 10vw 0 5vw 0;
      border-radius: 3vw;
      background-color: whitesmoke;
      flex-direction: column;

      hr {
        display: none;
      }
      .division1 {
        display: flex;
        flex-direction: column;
        gap: 5vw;
        .cart-products-container {
          align-items: stretch;
          padding: 0 8vw;
          .cart-product {
            padding: 0;
            
            .product-details{
             padding: 0;
             max-width: min-content;
             .image-container{
              width: 20vw;
             }
             
            }
           
            .quantity-handler {
              border-color: black;
              button {
                font-size: 3vw;
              }
            }
          }
        }
        .cart-head {
          background-color: black;
          color: white;
          padding: 2vw 8vw;
        }
      }
      .division2 {
        .cart-total-section {
          padding: 5vw 9vw;
        }
        .checkout-section {
          justify-content: center;
          button {
            background-color: black;
            color: white;
            font-size: 3vw;
            border-radius: 2vw;
          }
        }
       .ship-info{
       
        font-size: 2vw;
       }
      }
    }
  }
}
