$desiredWidth: 350px; // Your desired width
// Calculate the final width using max-width and percentage functions
$tableWidth: max($desiredWidth, 50%);
.shippingPolicy {
  display: flex;
  flex-direction: column;
  padding: 5%;
  padding-top: 3%;
  gap: 10px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  .shippingPolicy_title {
    text-align: center;
    font-size: 25px;
    margin-bottom: 15px;
  }
  .shippingPolicy_subtitle {
    font-size: 20px;
    margin-bottom: 10px;
    text-decoration: underline;
  }
  .shippingPolicy_text {
    font-size: 15px;
    margin-bottom: 5px;
  }
  table {
    width: $tableWidth;
    border: 1px solid #ddd;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 15px;
    thead,
    tbody,
    th,
    td {
      border: 1px solid #ddd;
    }
    th {
      text-align: left;
      font-weight: bold;
      padding: 10px 20px;
      padding-right: 35px;
    }
    td {
      padding: 10px 20px;
      padding-right: 35px;
      text-align: left;
      font-size: 15px;
      font-weight: normal;
      vertical-align: top;
    }
  }
}
