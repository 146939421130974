.appbar{
  position:sticky;
  top: 0;
  left: 5px;
  right: 5px;
  z-index: 100;
}
.languageSwitch{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0.75vw 3vw;
    background-color: black;
    color: whitesmoke;
   
  }
  .rtl-container{
    direction: rtl;
    text-align: right;
  }
  .languageSwitch button {
    background-color: transparent;
    border: none;
    color: whitesmoke;
    font-size: x-small;
  }
  .languageSwitch button:hover{
   cursor: pointer;
  }

  @media (max-width: 768px) {
    .appbar{
      left: 0;
      right: 0;
    }
    
  }

  