.adminForgotPassword {
  display: flex;
  flex-direction: column;
  padding: 2vw 0;
}
.adminForgotPassword .title {
  text-align: center;
}
.adminForgotPassword .form-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.5vw;
  padding: 2vw 7vw;
}
.adminForgotPassword .form-group label {
  font-size: 1.5vw;
  font-weight: bold;
}
.adminForgotPassword .form-group input {
  padding: 1vw;
  font-size: 1.5vw;
}
.adminForgotPassword .form-group .grouping-with-errorbox {
  display: flex;
  flex-direction: column;
  gap: 1vw;
}
.adminForgotPassword .form-group .grouping-with-errorbox .error-msg {
  color: rgb(248, 78, 78);
  font-size: 1vw;
}
.adminForgotPassword .form-group .submit-button {
  padding: 1vw;
  font-size: 1.5vw;
  cursor: pointer;
}/*# sourceMappingURL=AdminForgotPassword.css.map */