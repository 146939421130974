.adminResetPassword{
    display: flex;
    flex-direction: column;
    padding: 2vw 0;
    justify-content: center;
    .token-status-error{
        padding: 25vh 0;
    }
    .title{
        text-align: center;
    }
    .token-status-error{
       text-align: center;
    }
    .form-group{
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 1.5vw;
        padding: 2vw 7vw;
         label{
            font-size: 1.5vw;
            font-weight: bold;
         }
        input{
            padding: 1vw;
            font-size: 1.5vw;
        }
        .grouping-with-errorbox {
            display: flex;
            flex-direction: column;
            gap: 1vw;
            .error-msg {
              color: rgb(248, 78, 78);
              font-size: 1vw;
            }
          }
          .submit-button {
            padding: 1vw;
            font-size: 1.5vw;
            cursor: pointer;
          }
    }
}