.orders-container {
  padding: 2vw;

  .empty-order {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50vh;
  }
  .all-orders {
    display: flex;
    flex-direction: column;
    gap: 1vw;
    .order-container {
      .order {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2vw 5vw;
        border: 0.1vw solid lightgray;

        .product {
          display: flex;
          flex-direction: column;
          gap: 1vw;
          .image-container {
            width: 10vw;
            height: auto;
            border: 0.1vw solid black;
            img {
              width: inherit;
              height: inherit;
              cursor: pointer;
            }
          }
        }
        .price {
          text-align: left;
          width: 10vw;
        }
        .quantity {
          text-align: left;
          width: 10vw;
        }
        .status {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          width: 20vw;
          gap: 1vw;
          a {
            font-size: 1.5vw;
            text-decoration: none;
          }
        }
      }
      .additional-data {
        display: flex;
        flex-wrap: wrap;
        height: max-content;
        justify-content: space-around;
        gap: 2vw;
        padding: 1vw 2vw;
        background-color: whitesmoke;
        box-shadow: 0.5px 1px 0.5px 1px rgba(0, 0, 0, 0.2);
        .divider {
          display: flex;
          flex-direction: column;
          gap: 1vw;
        }
        .address {
          display: flex;
          flex-direction: column;
        }
        .btn-cancel-return{
          display: flex;
          align-items: center;
          justify-content: center;
          button{
            margin: 20px 0; // Adjust margin as needed for spacing between photos and button
            padding: 10px;
            background-color: #e25c5c; // Add your preferred button styling
            color: #fff;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            font-size: 16px;
            &:hover{
              background-color: #f37e75;
            }
            @media (orientation : portrait){
              font-size: 8px;
            }
          }
        }
      }
    }
  }
}


