.fp-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  padding: 0 25px;
 
  
  section {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
    height: 250px;
    max-width: 500px;
       
    button{
      border-radius: 5px;
      border: 1px solid grey;
      padding: 5px;
      &:hover {
       padding: 5px 10px;
       background-color:#fff;
      }
    }
    .fp-header {
      display: flex;
      align-items: center;
      background-color: whitesmoke;
      height: 40px;
      padding: 25px;
      position: relative;
      font-size: 18px;
      h5{
        font-size: large;
      }

      .fp-close {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
      }
    }
    .fp-body {
      background-color: #F8F8FF;
      display: flex;
      gap: 10px;
      flex-direction: column;
      height: inherit;
      align-items: center;
      padding: 10px;
      .fp-text {
        text-align: center;
        font-weight: bold;
        font-size: inherit;
        padding: 10px 20px;
      }
      .fp-form {
       
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        width: 250px;
        .fp-input {
          border-radius: 5px;
          border: 1px solid black;
          padding: 5px 10px;
          width: inherit;
          &:focus {
            outline: 2px solid darkslateblue;
            border: none;
          }
          
          &::placeholder {
            color: lightslategrey;
          }
        }
        .fp-submit {
          width: 150px;
        }
      }
      .fp-success{
        color: green;
        .fp-success-icon{
          color: green;
        }
      }
      .fp-error{
        color: red;

      }
    }
    .fp-footer {
      display: flex;
      padding: 10px 25px;
      background-color: whitesmoke;
      justify-content: space-between;
      h5{
       width: 75px;
       font-weight: bold;
       cursor: pointer;
       font-size: medium;
      }
    }
    
  }
}
