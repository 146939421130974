.addAdmin {
  display: flex;
  flex-direction: column;
  padding: 2vw 3vw;
  justify-content: center;
}
.addAdmin .title {
  text-align: center;
}
.addAdmin .form-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.5vw;
  padding: 5vw;
}
.addAdmin .form-group input {
  padding: 1vw;
  font-size: 1.5vw;
}
.addAdmin .form-group .grouping-with-errorbox {
  display: flex;
  flex-direction: column;
  gap: 1vw;
}
.addAdmin .form-group .grouping-with-errorbox .error-msg {
  color: rgb(248, 78, 78);
  font-size: 1vw;
}
.addAdmin .form-group .submit-button {
  padding: 1vw;
  font-size: 1.5vw;
  cursor: pointer;
}/*# sourceMappingURL=AdminAddAdmin.css.map */