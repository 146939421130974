.adminNavbar{
    display: flex;
    gap: 2vw;
    padding: 2vw;
    background-color: whitesmoke;
    color: #251a02;
    position: relative;
    z-index: 100;
    
    .menu-logo-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1vw;
        position:sticky;
        top: 50%;
        left: 50%;
        transform: (translate(-50%, 0));
        img{
            width: 100px;
        }
        
    }
    .menu-list{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2vw;
        a{
            text-decoration: none;
            color: #251a02;
            font-size: 1.5vw;
            @media(max-width: 768px){
                font-size: 12px;
                
            }
        }
        .active{
            color: #c7b199;
        }
    }
    .user{
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 5px;
        top:40px;
        right: 30px;
        @media (max-width: 768px){ 
            top:10px;
            right: 10px;
        }
        @media (max-width: 900px){
            top: 20px;
            right: 15px;
        }
        .user-icon{
            font-size: 25px;
            @media (max-width: 768px){
                font-size: 12px;
            }
        }
        .logout-btn,.login-btn{
            cursor: pointer;
            padding: 5px 10px;
            font-size: 12px;
            
            @media (max-width: 768px){
                font-size: 8px;
                font-weight: bold;
                padding: 2.5px 5px;
            }
        }
        .admin-email{
            font-size: 16px;
            @media (max-width: 768px){
                font-size: 8px;
                font-weight: bold;
            }
        }
    }
}