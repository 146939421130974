.product-container {
  display: flex;
  gap: 5vw;
  align-items: center;
  justify-content: center;
}
.product-container .product-images {
  display: flex;
  flex-direction: column;
  gap: 1vw;
  padding: 5vw;
}
.product-container .product-images .product-main-image-container {
  width: 25vw;
  height: auto;
  position: relative;
}
.product-container .product-images .product-main-image-container .outOfStockOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  z-index: 1;
  pointer-events: none;
}
.product-container .product-images .product-main-image-container img {
  height: inherit;
  width: inherit;
  -o-object-fit: cover;
     object-fit: cover;
}
.product-container .product-images .product-secondary-images-container {
  display: flex;
  max-width: 25vw;
  overflow-x: auto;
  overflow-y: hidden;
  gap: 1vw;
}
.product-container .product-images .product-secondary-images-container .image-container {
  display: flex;
  align-items: center;
  width: 4.2vw;
  height: auto;
}
.product-container .product-images .product-secondary-images-container .image-container.selected {
  border: 0.1vw solid black;
  overflow: hidden;
}
.product-container .product-images .product-secondary-images-container .image-container img {
  width: inherit;
  height: inherit;
  cursor: pointer;
  -o-object-fit: cover;
     object-fit: cover;
}
.product-container .product-purchase-content {
  max-width: 356px;
  display: flex;
  gap: 1.5vw;
  flex-direction: column;
  padding-top: 50px;
  justify-content: center;
}
.product-container .product-purchase-content .productTitle {
  font-size: 2vw;
}
.product-container .product-purchase-content .ratingContainer {
  display: flex;
  align-items: center;
  gap: 0.5vw;
}
.product-container .product-purchase-content .ratingContainer span {
  line-height: 0.05vw;
}
.product-container .product-purchase-content .per-notes {
  display: flex;
  flex-direction: column;
  gap: 0.5vw;
}
.product-container .product-purchase-content .per-notes #note-head {
  font-weight: bold;
  text-decoration: underline;
}
.product-container .product-purchase-content .per-notes .notes {
  font-weight: bold;
}
.product-container .product-purchase-content .per-notes .notes .note-name {
  font-weight: lighter;
}
.product-container .product-purchase-content .action1 {
  display: flex;
  gap: 1vw;
  align-items: flex-end;
}
.product-container .product-purchase-content .action1 .addToCartBtn {
  width: -moz-fit-content;
  width: fit-content;
  padding: 1vw 5vw;
  font-size: 1vw;
  cursor: pointer;
  border: none;
  background-color: black;
  color: white;
}
.product-container .product-purchase-content .action1 .disabledBtn {
  pointer-events: none;
  background-color: rgba(230, 221, 221, 0.7137254902);
  color: black;
}
.product-container .product-purchase-content .action1 .product-quantity-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5vw;
}
.product-container .product-purchase-content .action1 .product-quantity-wrapper .product-quantity {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  font-size: 1.5vw;
  gap: 1vw;
  padding: 0.5vw 2vw;
  border: 1px solid black;
}
.product-container .product-purchase-content .action1 .product-quantity-wrapper .product-quantity button {
  background-color: transparent;
  cursor: pointer;
  font-size: 1.5vw;
  border: none;
}
.product-container .product-purchase-content .product-actions {
  display: flex;
  gap: 3vw;
}
.product-container .product-purchase-content .product-actions button {
  width: 100%;
  padding: 0.5vw 5vw;
  font-size: 1vw;
  cursor: pointer;
  border: none;
}
.product-container .product-purchase-content .product-actions .disabledBtn {
  pointer-events: none;
  background-color: rgba(230, 221, 221, 0.7137254902);
  color: black;
}

.product-details {
  padding-top: 50px;
}
.product-details .action-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2vw;
}
.product-details .action-btns button {
  padding: 1vw 3vw;
  cursor: pointer;
  font-size: 2vw;
  border: none;
  background-color: transparent;
}
.product-details .action-btns button.selected {
  border-bottom: 0.25vw solid black;
}
.product-details .overview, .product-details .rating-n-review-section {
  padding: 3vw 5vw;
  background-color: white;
}
.product-details .rating-n-review-section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5vw;
}
.product-details .rating-n-review-section .rating-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  gap: 3vw;
  padding: 2vw;
}
.product-details .rating-n-review-section .rating-section .rating {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.product-details .rating-n-review-section .review-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  gap: 2vw;
  height: inherit;
  overflow-y: auto;
}
.product-details .rating-n-review-section .review-section .review-head {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.product-details .rating-n-review-section .review-section .review-head .rate-btn {
  cursor: pointer;
  border: none;
  font-size: 1.5vw;
}
.product-details .rating-n-review-section .review-section .reviewBoxes {
  display: flex;
  flex-direction: column;
  gap: 1vw;
  width: 100%;
}
.product-details .rating-n-review-section .review-section .reviewBoxes .reviewBox {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: rgba(230, 221, 221, 0.8784313725);
  padding: 1vw;
  border-radius: 0.5vw;
  gap: 0.5vw;
}
.product-details .rating-n-review-section .review-section .reviewBoxes .reviewBox .name {
  font-weight: bold;
}
.product-details .rating-n-review-section .review-section button {
  margin-top: 1vw;
  padding: 0.5vw 3vw;
  font-size: 1.5vw;
  border: 0.1vw solid black;
  background-color: rgba(230, 221, 221, 0.8784313725);
  cursor: pointer;
}

.overview {
  font-size: 1.25vw;
}

.loadingIndicator {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2vw;
  height: 50vh;
}

@media screen and (max-width: 768px) {
  .ratingContainer {
    gap: 1vw;
  }
  .product-container {
    flex-direction: column;
    padding-bottom: 10vh;
  }
  .product-container .product-images .product-main-image-container {
    width: 75vw;
    height: auto;
  }
  .product-container .product-images .product-secondary-images-container {
    max-width: 50vw;
  }
  .product-container .product-images .product-secondary-images-container .image-container {
    width: 14.2vw;
  }
  .product-container .product-purchase-content {
    width: 75vw;
  }
  .product-container .product-purchase-content p, .product-container .product-purchase-content h6 {
    font-size: 3vw;
  }
  .product-container .product-purchase-content .productTitle {
    font-size: 4vw;
  }
  .product-container .product-purchase-content .ratingContainer span {
    font-size: 3vw;
  }
  .product-container .product-purchase-content .action1 {
    width: 100%;
  }
  .product-container .product-purchase-content .action1 .product-quantity-wrapper .product-quantity {
    padding: 1vw 2vw;
    font-size: 3vw;
    flex: 1;
  }
  .product-container .product-purchase-content .action1 .product-quantity-wrapper .product-quantity button {
    font-size: 3vw;
  }
  .product-container .product-purchase-content .action1 .addToCartBtn {
    flex: 1;
    padding: 1.5vw 5vw;
    font-size: 3vw;
  }
  .product-container .product-purchase-content .product-actions {
    padding: 1vw 0;
  }
  .product-container .product-purchase-content .product-actions button {
    font-size: 3vw;
    padding: 2vw 5vw;
  }
  .product-details {
    padding: 5vw;
  }
  .product-details .action-btns button {
    font-size: 3vw;
  }
  .product-details .overview {
    font-size: 3vw;
  }
  .product-details .rating-n-review-section {
    flex-direction: column;
    align-items: normal;
  }
  .product-details .rating-n-review-section .rating-section {
    flex-direction: row;
  }
  .product-details .rating-n-review-section .review-section p {
    font-size: 3vw;
  }
  .product-details .rating-n-review-section .review-section button {
    font-size: 3vw;
  }
  .product-details .rating-n-review-section .review-section .review-head .rate-btn {
    font-size: 2.5vw;
    border-radius: 1vw;
  }
  .product-details .rating-n-review-section .review-section .reviewBoxes {
    padding: 1vw 2vw;
  }
  .product-details .rating-n-review-section .review-section .reviewBoxes .reviewBox {
    font-size: 3vw;
  }
}/*# sourceMappingURL=productPage.css.map */